<template>
  <div class="width-100 height-100 max_box">
    <div class="audio_box none">
      <audio src="audio/zhongxin2/Loveless.mp3" ref="bgAudio"></audio>
      <audio ref="audio1" src="audio/yc/1.mp3"></audio>
      <audio ref="audio2" src="audio/yc/2.mp3"></audio>
      <audio ref="audio3" src="audio/yc/3.mp3"></audio>
      <audio ref="audio4" src="audio/yc/4.mp3"></audio>
      <audio ref="audio5" src="audio/yc/5.mp3"></audio>
      <audio ref="audio6" src="audio/yc/6.mp3"></audio>
      <audio ref="audio7" src="audio/yc/7.mp3"></audio>
      <audio ref="audio8" src="audio/yc/8.mp3"></audio>
      <audio ref="audio9" src="audio/yc/9.mp3"></audio>
      <audio ref="audio10" src="audio/yc/10.mp3"></audio>
      <audio ref="audio11" src="audio/yc/11.mp3"></audio>
      <audio ref="audio12" src="audio/yc/risk.mp3"></audio>
    </div>
        <div class="cover" id="cover" v-if="isLoadingVisible"></div>
    <div
      class="card1 card animate__animated"
      id="card1"
      v-show="currentIndex == 1"
    >
      <div class="top none">
        <div class="img_box">
          <img src="../../assets/img/yc/logo1.png" alt="" />
        </div>
        <div>市场研究部</div>
      </div>
      <div class="text1 none">
        <span>智富周策略</span>
        <span></span>
      </div>
      <div class="text2 none" id="text2">新毒株剧烈冲击A股影响几何？</div>
      <div class="text3" id="text3">
        <div class="img_box none">
          <img src="../../assets/img/yc/shabox.png" alt="" />
        </div>
        <div class="time none" id="time">11月22日-11月26日</div>
      </div>
      <div class="text4 none" id="text4">
        <div class="img_box">
          <img src="../../assets/img/yc//logo2.png" alt="" />
        </div>
        <div>第一创业证券</div>
      </div>
      <div class="text5 none">更新时间：2021年11月28日</div>
    </div>
    <div class="card2 card animate__animated" v-show="currentIndex == 2">
      <div class="title none">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name" id="t_name1">
          摘要<span style="font-size: 31px">（本周11月22日-11月26日）</span>
        </div>
      </div>
      <div class="content_box none">
        <div class="content">
          <div class="top">
            <div class="left">
              <img src="../../assets//img/yc/left.png" alt="" />
            </div>
            <div class="tit">事 件</div>
            <div class="right">
              <img src="../../assets//img/yc/right.png" alt="" />
            </div>
          </div>
          <div class="desc1">
            周末南非“奥密克戎”毒王来袭，全球资本市场剧烈波动。
          </div>
          <div class="desc2">
            奥密克戎在南非所有变种的占比 <span>高达90% </span>
          </div>
          <donut-chart
            ref="refDonutChart1"
            id="refDonutChart1"
            customClass="c_donut1"
          >
          </donut-chart>
          <div class="word none">
            <div>奥密克戎</div>
            <div style="font-size: 50px; margin-left: -16px">高达90%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card3 card animate__animated" v-show="currentIndex == 3">
      <div class="title none">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">行业配置</div>
      </div>
      <div class="content_box none">
        <div class="content">
          <div class="dec">
            市场中期仍呈现出低波动率下的慢牛行情，短期如果全球疫情持续超预期，或再次呈现“防疫”下的短期轮动策略。
          </div>
        </div>
      </div>
      <force-chart
        ref="refForceChart1"
        id="refForceChart1"
        customClass="c_force1"
      >
      </force-chart>
      <svg width="300" height="300" class="svg_gradient">
        <defs>
          <radialGradient
            id="radial1"
            fx="20%"
            fy="80%"
            cx="20%"
            cy="80%"
            r="50%"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="20%" stop-color="#F8BE73" stop-opacity="1" />
            <stop offset="80%" stop-color="#EE8A3F" stop-opacity="1" />
          </radialGradient>
        </defs>
      </svg>
    </div>
    <div class="card4 card animate__animated" v-show="currentIndex == 4">
      <div class="title none">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">风险因素</div>
      </div>
      <ul>
        <li>
          <div class="circle none">
            <div class="img_box">
              <img src="../../assets/img/yc/circle.png" alt="" />
            </div>
            <div class="num">1</div>
          </div>
          <div class="line none">
            <img src="../../assets//img/yc/dashed.png" alt="" />
          </div>
          <div class="content_box none">
            <div class="box">
              <img src="../../assets//img//yc/box.png" alt="" />
            </div>
            <div class="content">病毒毒性和传染性超预</div>
          </div>
        </li>
        <li>
          <div class="circle none">
            <div class="img_box">
              <img src="../../assets/img/yc/circle.png" alt="" />
            </div>
            <div class="num">2</div>
          </div>
          <div class="line none">
            <img src="../../assets//img/yc/dashed.png" alt="" />
          </div>
          <div class="content_box none">
            <div class="box">
              <img src="../../assets//img//yc/box.png" alt="" />
            </div>
            <div class="content">通胀大幅超预期</div>
          </div>
        </li>
        <li>
          <div class="circle none">
            <div class="img_box">
              <img src="../../assets/img/yc/circle.png" alt="" />
            </div>
            <div class="num">3</div>
          </div>
          <div class="line none">
            <img src="../../assets//img/yc/dashed.png" alt="" />
          </div>
          <div class="content_box none">
            <div class="box">
              <img src="../../assets//img//yc/box.png" alt="" />
            </div>
            <div class="content">货币政策超预期收紧</div>
          </div>
        </li>
        <li>
          <div class="circle none">
            <div class="img_box">
              <img src="../../assets/img/yc/circle.png" alt="" />
            </div>
            <div class="num">4</div>
          </div>
          <div class="line none">
            <img src="../../assets//img/yc/dashed.png" alt="" />
          </div>
          <div class="content_box none">
            <div class="box">
              <img src="../../assets//img//yc/box.png" alt="" />
            </div>
            <div class="content">经济下行压力超预期</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="card5 card animate__animated" v-show="currentIndex == 5">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">
          本周市场回顾<span style="font-size: 31px">(市场走势)</span>
        </div>
      </div>
      <div class="content_box">
        <div class="content none">
          <div class="img">
            <img src="../../assets//img/yc/box_sha.png" alt="" />
          </div>
          <div class="dec">本周A股先冲高后回落，短期呈现偏强震荡格局</div>
          <ul>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg.png" alt="" />
              </div>
              <div class="line1">沪指</div>
              <div class="line2">
                <div class="left">报3564.09点</div>
                <div class="right none">
                  <span id="num1">0.1</span><span>%</span>
                </div>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg.png" alt="" />
              </div>
              <div class="line1">深成指</div>
              <div class="line2">
                <div class="left">报14777.17点</div>
                <div class="right none">
                  <span id="num2">0.17</span><span>%</span>
                </div>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg.png" alt="" />
              </div>
              <div class="line1">创业板指</div>
              <div class="line2">
                <div class="left">报3468.87点</div>
                <div class="right none">
                  <span id="num3">1.46</span><span>%</span>
                </div>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg2.png" alt="" />
              </div>
              <div class="line2">
                <div class="left">两市日均成交额</div>
                <div class="right none">
                  <span id="num4" style="font-size: 46px">1.17</span
                  ><span>万亿元</span>
                </div>
              </div>
            </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card6 card animate__animated" v-show="currentIndex == 6">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">
          行业观察<span style="font-size: 31px">(本周A股行业涨幅TOP7)</span>
        </div>
      </div>
      <div class="content_box">
        <bar-chart-horizen
          ref="refBarChart1"
          id="refBarChart1"
          customClass="c_bar1"
        >
        </bar-chart-horizen>
        <svg width="300" height="300" class="svg_gradient">
          <defs>
            <linearGradient id="linear1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stop-color="#FB5400" />
              <stop offset="100%" stop-color="#FF9139" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="tips">数据来源：Wind，第一创业证券整理</div>
    </div>
    <div class="card7 card animate__animated" v-show="currentIndex == 7">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">
          行业观察<span style="font-size: 31px">(本周A股行业跌幅TOP6)</span>
        </div>
      </div>
      <div class="content_box">
        <bar-chart-horizen
          ref="refBarChart2"
          id="refBarChart2"
          customClass="c_bar1"
        >
        </bar-chart-horizen>
        <svg width="300" height="300" class="svg_gradient">
          <defs>
            <linearGradient id="linear2" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stop-color="#FE9138" />
              <stop offset="100%" stop-color="#74FFBA" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="tips">数据来源：Wind，第一创业证券整理</div>
    </div>
    <div class="card8 card animate__animated" v-show="currentIndex == 8">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">
          资金动态<span style="font-size: 31px"
            >(沪深两市融资融券余额（亿元) )</span
          >
        </div>
      </div>
      <div class="leng none">
        <div>
          <span class="sp1"></span>
          <span>融资余额</span>
        </div>
        <div>
          <span class="sp2"></span>
          <span>融券余额</span>
        </div>
      </div>
      <line-chart
        ref="refLineChart2"
        id="refLineChart2"
        customClass="c_line2"
      />
      <div class="img_1 none">
        <img src="../../assets//img/yc/blue_dot.png" alt="" />
      </div>
      <div class="img_2 none">
        <img src="../../assets//img/yc/blue_box.png" alt="" />
      </div>
      <div class="num_box1 none">-32.89</div>
      <div class="img_3 none">
        <img src="../../assets//img/yc/ye_dot.png" alt="" />
      </div>
      <div class="img_4 none">
        <img src="../../assets//img/yc/ye_box.png" alt="" />
      </div>
      <div class="num_box2 none">209.42</div>

      <div class="tips">数据来源：Wind，第一创业证券整理</div>
    </div>
    <div class="card9 card animate__animated" v-show="currentIndex == 9">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">
          资金动态<span style="font-size: 31px"
            >(本周北上资金变动状态（亿元）)</span
          >
        </div>
      </div>
      <div class="legend none">
        <div>
          <img src="../../assets/img/yc/sql.png" alt="" />
          净流入
        </div>
        <div>
          <img src="../../assets/img/yc/color.png" alt="" />
          净流出
        </div>
      </div>
      <div class="value_box none">
        <img src="../../assets/img/yc/text_box.png" alt="" />
        <div class="val">累计57.1亿元</div>
      </div>
      <bar-chart ref="refBarChart5" id="refBarChart5" customClass="c_bar3">
      </bar-chart>

      <svg width="300" height="300" class="svg_gradient">
        <defs>
          <linearGradient id="barPositive" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#FF9139" />
            <stop offset="100%" stop-color="#FB5400" />
          </linearGradient>
          <linearGradient id="barNegative" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#FE9138" />
            <stop offset="100%" stop-color="#74FFBA" />
          </linearGradient>
        </defs>
      </svg>

      <div class="tips">数据来源：Wind，第一创业证券整理</div>
    </div>
    <div class="card10 card animate__animated" v-show="currentIndex == 10">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">本周全球股市涨跌幅一览</div>
      </div>

      <div class="content_box">
        <tree-map
          ref="refTreeMap1"
          id="refTreeMap1"
          customClass="c_tree_map1"
        />
      </div>
      <div class="time">数据时间：2021年12月23日</div>
      <div class="tips">数据来源：Wind，第一创业证券整理</div>
    </div>
    <div class="card11 card animate__animated" v-show="currentIndex == 11">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">本周美元指数及重要大宗商品表现</div>
      </div>
      <div class="content_box">
        <div class="content none">
          <div class="img">
            <img src="../../assets//img/yc/box_sha.png" alt="" />
          </div>
          <ul>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg.png" alt="" />
              </div>
              <div class="line1">美元指数</div>
              <div class="line2">
                <div class="left">报96.1点</div>
                <div class="right none">
                  <span>0.00%</span>
                </div>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg.png" alt="" />
              </div>
              <div class="line1">布伦特原油</div>
              <div class="line2">
                <div class="left">报3564.09点</div>
                <div class="img_down none">
                  <img src="../../assets//img/yc//down.png" alt="" />
                </div>
                <div class="right none">
                  <span>-</span>
                  <span id="num5">8.99</span><span>%</span>
                </div>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg.png" alt="" />
              </div>
              <div class="line1">伦铜</div>
              <div class="line2">
                <div class="left">报3564.09点</div>
                <div class="img_down none">
                  <img src="../../assets//img/yc//up.png" alt="" />
                </div>
                <div class="right none">
                  <span>+</span>
                  <span id="num6">2.90</span><span>%</span>
                </div>
              </div>
            </li>
            <li>
              <div class="img_box">
                <img src="../../assets//img/yc/box_bg.png" alt="" />
              </div>
              <div class="line1">美元指数</div>
              <div class="line2">
                <div class="left">报3564.09点</div>
                <div class="img_down none">
                  <img src="../../assets//img/yc//down.png" alt="" />
                </div>
                <div class="right none">
                  <span>-</span>
                  <span id="num7">3.90</span><span>%</span>
                </div>
              </div>
            </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card12 card animate__animated" v-show="currentIndex == 12">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">下周A股市场看点</div>
      </div>
      <div class="content_box none">
        <div class="content">
          <ul>
            <li>
              <div class="circle">
                <div class="img_box">
                  <img src="../../assets/img/yc/circle.png" alt="" />
                </div>
                <div class="num">1</div>
              </div>
              <div class="text">
                在南非等国发现奥密克戎毒株已经引起全球高度警惕
              </div>
            </li>
            <li>
              <div class="circle">
                <div class="img_box">
                  <img src="../../assets/img/yc/circle.png" alt="" />
                </div>
                <div class="num">2</div>
              </div>
              <div class="text">收款二维码到底还能不能用了？</div>
            </li>
            <li>
              <div class="circle">
                <div class="img_box">
                  <img src="../../assets/img/yc/circle.png" alt="" />
                </div>
                <div class="num">3</div>
              </div>
              <div class="text">
                10月份全国规模以上工业企业利润同比增长24.6%
              </div>
            </li>
            <li>
              <div class="circle">
                <div class="img_box">
                  <img src="../../assets/img/yc/circle.png" alt="" />
                </div>
                <div class="num">4</div>
              </div>
              <div class="text">
                多家银行已启动“开门红”活动，为明年业务发展蓄势
              </div>
            </li>
            <li>
              <div class="circle">
                <div class="img_box">
                  <img src="../../assets/img/yc/circle.png" alt="" />
                </div>
                <div class="num">5</div>
              </div>
              <div class="text">北交所上市委召开首次审议会议威博液压过会</div>
            </li>
            <li>
              <div class="circle">
                <div class="img_box">
                  <img src="../../assets/img/yc/circle.png" alt="" />
                </div>
                <div class="num">6</div>
              </div>
              <div class="text">胰岛素首进国家集采</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card13 card animate__animated" v-show="currentIndex == 13">
      <div class="title none">
        <div class="img_box">
          <img src="../../assets/img/yc//y_box.png" alt="" />
        </div>
        <div class="t_name">免责声明</div>
      </div>
      <div class="content_box none">
        <div class="content">
          <div class="text">
            本视频资讯信息、数据资料来源于第三方，平台会尽力但不保证信息的真实性、准确性、完整性及原则性等。一创智富通转载仅供投资者参考，并不代表第一创业证券观点，不构成任何投资建议，您自行承担因信赖或使用第三方信息而导致的任何损失。投资有风险，入市需谨慎。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams, formatToDate, GetParams2 } from "../../utils/common";
import { anyElement } from "@/components/anyElement.js";
import donutChart from "@/components/donutChart/index.vue";
import forceChart from "@/components/forceChart/index.vue";
import barChart from "@/components/barChart/index.vue";
import barChartHorizen from "@/components/barChartHorizen/index.vue";
import lineChart from "@/components/lineChart/index.vue";
import treeMap from "@/components/treeMap/index.vue";
import { sleep, numberRoll } from "@/utils/common";
import ApiServe from "@/api/index";
export default {
  name: "changjiang",
  components: {
    donutChart,
    forceChart,
    barChartHorizen,
    lineChart,
    barChart,
    treeMap,
  },
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      recordtimeAll: null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      currentIndex: 1,
      isLoadingVisible:true,
      population2: [
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
        { name: "", value: 256 },
        { name: "", value: 236 },
      ],
      forceData: [
        { value: 18, title: "医药生物", subTitle: "短期" },
        { value: 22, title: "制造业", subTitle: "短期" },
        { value: 14, title: "消费", subTitle: "中期" },
        { value: 22, title: "科技成长", subTitle: "中期" },
        { value: 16, title: "食品安全", subTitle: "中期" },
        { value: 16, title: "农业牧渔", subTitle: "中期" },
      ],
      market2: [
        { label: "有色金属", value: 6.0 },
        { label: "钢铁", value: 4.04 },
        { label: "食品饮料", value: 2.5 },
        { label: "医药生物", value: 1.69 },
        { label: "商业贸易", value: 1.58 },
        { label: "采掘", value: 1.5 },
        { label: "消费", value: 1.25 },
      ],
      market3: [
        { label: "休闲服务", value: -5.29 },
        { label: "农林牧渔", value: -3.19 },
        { label: "国防军工", value: -2.23 },
        { label: "银行", value: -1.94 },
        { label: "传媒", value: -1.88 },
        { label: "交通运输", value: -1.56 },
      ],
      flareData1: [
        { name: "flare", size: null },
        { name: "flare.创业板指", size: 1.55 },
        { name: "flare.上证综指", size: 0.1 },
        { name: "flare.深圳成指", size: 0.17 },
        { name: "flare.纳斯达克", size: 3.52 },
        { name: "flare.道琼斯工业", size: 1.95 },
        { name: "flare.日经225", size: 3.34 },
        { name: "flare.德国DAX", size: 5.59 },
        { name: "flare.富时100", size: 2.49 },
        { name: "flare.恒生指数", size: 3.87 },
        { name: "flare.韩国综指", size: 1.16 },
        { name: "flare.印度孟买30", size: 4.24 },
      ],

      lineData: [
        { label: "2021.12.01", value1: 17177.97, value2: 1314.9 },
        { label: "2021.12.02", value1: 61700, value2: 31700 },
        { label: "2021.12.03", value1: 31700, value2: 41700 },
        { label: "2021.12.04", value1: 121700, value2: 61700 },
        { label: "2021.12.05", value1: 51700, value2: 51700 },
        { label: "2021.12.06", value1: 64700, value2: 41700 },
        { label: "2021.12.07", value1: 91700, value2: 21700 },
        { label: "2021.12.08", value1: 71700, value2: 31700 },
        { label: "2021.12.09", value1: 81700, value2: 51700 },
        { label: "2021.12.10", value1: 11700, value2: 61700 },
        { label: "2021.12.11", value1: 87000, value2: 41700 },
        { label: "2021.12.12", value1: 6700, value2: 81700 },
        { label: "2021.12.13", value1: 14700, value2: 51700 },
        { label: "2021.12.14", value1: 9700, value2: 61700 },
        { label: "2021.12.15", value1: 10700, value2: 51700 },
      ],
      hasNegative: [
        { letter: "11/16", frequency: 41.9931 },
        { letter: "11/19", frequency: -20.7 },
        { letter: "11/22", frequency: 52.2268 },
        { letter: "11/23", frequency: 23.6967 },
        { letter: "11/24", frequency: -20.09 },
        { letter: "11/25", frequency: 11.7155 },
        { letter: "11/26", frequency: -44.89 },
      ],
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  async mounted() {
    if (this.ip && this.port) {
      console.log(666);
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {
          this.load();
        })
        .catch((err) => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    } else {
      // alert("没获取到ip,port");
      this.load();
    }
  },

  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },

    playOut() {
      this.txt.goOut.restart();
    },
    endRecord() {
      this.aduioData = [
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-b5c503f0.mp3",
          timeIndex: 2,
          audioLen: 2,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-6287a5f1.mp3",
          timeIndex: 8,
          audioLen: 7,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-58684ef4.mp3",
          timeIndex: 17,
          audioLen: 11,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-05fbc991.mp3",
          timeIndex: 30,
          audioLen: 7,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-02a2883f.mp3",
          timeIndex: 38,
          audioLen: 10,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-ec8cf5e0.mp3",
          timeIndex: 50,
          audioLen: 6,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-0f43855c.mp3",
          timeIndex: 58,
          audioLen: 2,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-c857a670.mp3",
          timeIndex: 63,
          audioLen: 8,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-61a6738b.mp3",
          timeIndex: 72,
          audioLen: 6,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-f7a92ef4.mp3",
          timeIndex: 80,
          audioLen: 12,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-de5aa452.mp3",
          timeIndex: 93,
          audioLen: 7,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-ac13c307.mp3",
          timeIndex: 101,
          audioLen: 2,
        },
      ];
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 108,
        videmBgm: "https://res.idtcdn.com/Cube/video-config/con-ec094cc4.mp3",
        audiosOrderList: this.aduioData,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    playIn1(refName) {
      this.$refs[refName].DonutChart(this.population2, {
        name: (d) => d.name,
        value: (d) => d.value,
        width: 382,
        height: 382,
        donutRatio: 0.75, //donut的内圆半径占比
        // labelRatio: 0.75, //label的半径占比
        padAngle: 15, //pad间隔角度
        cornerRadius: 4, //圆角
        colors: ["#34F5FA"],
        duration: 800,
        delay: 50,
        ease: "easeBackInOut",
        // labelPosition: "inside",
        valueType: "absolute",
        valueSuffix: "亿",
      });
    },
    playIn2(refName) {
      this.$refs[refName].ForceChart(this.forceData, {
        radius: (d) => d.value,
        width: 600,
        height: 600,
        duration: 600,
        delay: [50,80,1000,50,50,50],
        ease: "easeBack",
        radiusRange: [80, 90],
        circleGap: 6,
      });
    },
    playIn3(refName) {
      this.$refs[refName].BarChart(this.market2, {
        x: (d) => d.value,
        y: (d) => d.label,
        width: 600,
        height: 900,
        marginLeft: 80,
        marginRight: 80,
        duration: 400,
        delay: 400,
        yPadding: 0.5,
        ease: "easeExp",
        numberSuffix: "%",
        numberPosition: "outside",
        labelPosition: "top",
      });
    },
    playIn4(refName) {
      this.$refs[refName].BarChart(this.market3, {
        x: (d) => d.value,
        y: (d) => d.label,
        width: 600,
        height: 900,
        marginLeft: 80,
        marginRight: 100,
        duration: 400,
        delay: 400,
        yPadding: 0.5,
        ease: "easeExp",
        numberSuffix: "%",
        numberPosition: "outside",
        labelPosition: "top",
        // isNegativeSameDirection: true,
      });
    },
    playIn5(refName) {
      const chartWidth = (document.body.clientWidth - 40) * 0.3;
      this.$refs[refName].TreeMap(this.flareData1, {
        value: (d) => d?.size,
        width: 620,
        height: 900,
        valueSuffix: "%",
        padding: 4,
        rectRadius: 4,
        colors: [
          "#FFC269",
          "#FFBA57",
          "#FEB150",
          "#FEA847",
          "#FE9834",
          "#FE9638",
          "#FD8F32",
          "#FD862B",
          "#FD7D26",
          "#FC7420",
          "#FC6C1C",
        ].reverse(),
        duration: 400,
        delay: 20,
        ease: "easeCircleInOut",
      });
    },
    playIn6(refName) {
      this.$refs[refName].LineChart(this.lineData, {
        label: (d) => d.label,
        line1: (d) => d.value1,
        line2: (d) => d.value2,
        width: 650,
        height: 800,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 2000,
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        isFromZero: true,
        xTickNumber: 7,
        yTickNumber: 6,
      });
    },
    playIn7(refName) {
      this.$refs[refName].BarChart(this.hasNegative, {
        x: (d) => d.letter,
        y: (d) => d.frequency,
        width: 650,
        height: 800,
        marginLeft: 50,
        duration: 1000,
        delay: 70,
        ease: "easeCircle",
        xTickNumber: 7,
        yTickNumber: 10,
        xPadding: 0.6,
      });
    },
    // 页面card一个接一个播放
    async load() {
      this.$refs.bgAudio.volume = 0.1;
      this.$refs.bgAudio.play();
      await sleep(100);
      this.isLoadingVisible = false;
      await this.playPage1();
      await sleep(1000);
      await this.playPage2();
      await sleep(1000);
      await this.playPage3();
      await sleep(11000);
      await this.playPage4();
      await sleep(2000);
      await this.playPage5();
      await sleep(6500);
      await this.playPage6();
      await sleep(5000);
      await this.playPage7();
      await sleep(2500);
      await this.playPage8();
      await sleep(5000);
      await this.playPage9();
      await sleep(5000);
      await this.playPage10();
      await sleep(11000);
      await this.playPage11();
      await sleep(3000);
      await this.playPage12();
      await sleep(3000);
      await this.playPage13();

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, 109000);
      }
    },
    async playPage1() {
      this.currentIndex = 1;
      $("#card1 .top").removeClass("none");
      $("#card1 .top").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $("#card1 .text1").removeClass("none");
      $("#card1 .text1").addClass(" animate__animated animate__backInLeft");
      await sleep(1000);
      this.$refs.audio1.play();
      $("#card1 .text2").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#text2");
      await sleep(800);
      $("#card1 .text3 .img_box").removeClass("none");
      $("#card1 .text3 .img_box").addClass(
        " animate__animated animate__backInLeft"
      );
      await sleep(800);
      $("#card1 .text3 .time").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#time");
      await sleep(800);
      $("#card1 .text4").removeClass("none");
      $("#card1 .text4").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $("#card1 .text5").removeClass("none");
      $("#card1 .text5").addClass(" animate__animated animate__fadeIn");
    },
    async playPage2() {
      this.currentIndex = 2;
      await sleep(800);
      this.$refs.audio2.play();
      $(".card2 .title ").removeClass("none");
      $(".card2 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card2 .content_box ").removeClass("none");
      $(".card2 .content_box ").animate({ height: "747px" }, 1000);
      await sleep(3000);
      this.playIn1("refDonutChart1");
      await sleep(3500);
      $(".card2 .word ").removeClass("none");
      $(".card2 .word ").addClass(" animate__animated animate__bounceIn");
    },
    async playPage3() {
      await sleep(800);
      this.currentIndex = 3;
      this.$refs.audio3.play();
      $(".card3 .title ").removeClass("none");
      $(".card3 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card3 .content_box ").removeClass("none");
      $(".card3 .content_box ").animate({ width: "643px" }, 1000);
      await sleep(800);
      this.playIn2("refForceChart1");
    },
    async playPage4() {
      this.currentIndex = 4;
      this.$refs.audio12.play();
      $(".card4 .title ").removeClass("none");
      $(".card4 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .circle").eq(0).removeClass("none");
      $(".card4 li .circle")
        .eq(0)
        .addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .line").eq(0).removeClass("none");
      $(".card4 li .line").eq(0).addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .content_box").eq(0).removeClass("none");
      $(".card4 li .content_box")
        .eq(0)
        .addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .circle").eq(1).removeClass("none");
      $(".card4 li .circle")
        .eq(1)
        .addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .line").eq(1).removeClass("none");
      $(".card4 li .line").eq(1).addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .content_box").eq(1).removeClass("none");
      $(".card4 li .content_box")
        .eq(1)
        .addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .circle").eq(2).removeClass("none");
      $(".card4 li .circle")
        .eq(2)
        .addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .line").eq(2).removeClass("none");
      $(".card4 li .line").eq(2).addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .content_box").eq(2).removeClass("none");
      $(".card4 li .content_box")
        .eq(2)
        .addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .circle").eq(3).removeClass("none");
      $(".card4 li .circle")
        .eq(3)
        .addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .line").eq(3).removeClass("none");
      $(".card4 li .line").eq(3).addClass(" animate__animated animate__fadeIn");
      await sleep(500);
      $(".card4 li .content_box").eq(3).removeClass("none");
      $(".card4 li .content_box")
        .eq(3)
        .addClass(" animate__animated animate__fadeIn");
    },
    async playPage5() {
      await sleep(800);
      this.currentIndex = 5;
      this.$refs.audio4.play();
      $(".card5 .title ").removeClass("none");
      $(".card5 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card5 .content ").removeClass("none");
      $(".card5 .content ").animate({ height: "670px" }, 1000);
      await sleep(800);
      $(".card5 li .right ").eq(0).removeClass("none");
      numberRoll("num1", 0.1, 2);
      await sleep(800);
      $(".card5 li .right ").eq(1).removeClass("none");
      numberRoll("num2", 0.17, 2);
      await sleep(800);
      $(".card5 li .right ").eq(2).removeClass("none");
      numberRoll("num3", 1.46, 2);
      await sleep(800);
      $(".card5 li .right ").eq(3).removeClass("none");
      numberRoll("num4", 1.17, 2);
    },
    async playPage6() {
      await sleep(800);
      this.currentIndex = 6;
      this.$refs.audio5.play();
      $(".card6 .title ").removeClass("none");
      $(".card6 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card6 .content_box ").removeClass("none");
      $(".card6 .content_box ").animate({ width: "643px" }, 1000);
      await sleep(800);
      this.playIn3("refBarChart1");
    },
    async playPage7() {
      await sleep(800);
      this.currentIndex = 7;
      this.$refs.audio6.play();
      $(".card7 .title ").removeClass("none");
      $(".card7 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card7 .content_box ").removeClass("none");
      $(".card7 .content_box ").animate({ width: "643px" }, 1000);
      await sleep(800);
      this.playIn4("refBarChart2");
    },
    async playPage8() {
      await sleep(800);
      this.currentIndex = 8;
      this.$refs.audio7.play();
      $(".card8 .title ").removeClass("none");
      $(".card8 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(1000);
      $(".card8 .leng ").removeClass("none");
      $(".card8 .leng ").addClass(" animate__animated animate__fadeInRight");
      this.playIn6("refLineChart2");
      await sleep(800);
      $(".card8 .img_3 ").removeClass("none");
      $(".card8 .img_3 ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card8 .img_4 ").removeClass("none");
      $(".card8 .img_4 ").addClass(" animate__animated animate__fadeIn");
      $(".card8 .num_box2 ").removeClass("none");
      $(".card8 .num_box2 ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card8 .img_1 ").removeClass("none");
      $(".card8 .img_1 ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card8 .img_2 ").removeClass("none");
      $(".card8 .img_2 ").addClass(" animate__animated animate__fadeIn");
      $(".card8 .num_box1 ").removeClass("none");
      $(".card8 .num_box1 ").addClass(" animate__animated animate__fadeIn");
    },
    async playPage9() {
      await sleep(800);
      this.currentIndex = 9;
      this.$refs.audio8.play();
      $(".card9 .title ").removeClass("none");
      $(".card9 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card9 .legend ").removeClass("none");
      $(".card9 .legend ").addClass(" animate__animated animate__fadeInRight");
      await sleep(800);
      this.playIn7("refBarChart5");
      await sleep(800);
      $(".card9 .value_box ").removeClass("none");
      $(".card9 .value_box ").addClass(" animate__animated animate__zoomIn");
    },
    async playPage10() {
      await sleep(800);
      this.currentIndex = 10;
      this.$refs.audio9.play();
      $(".card10 .title ").removeClass("none");
      $(".card10 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      this.playIn5("refTreeMap1");
    },
    async playPage11() {
      await sleep(800);
      this.currentIndex = 11;
      this.$refs.audio10.play();
      $(".card11 .title ").removeClass("none");
      $(".card11 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card11 .content ").removeClass("none");
      $(".card11 .content ").animate({ height: "644px" }, 1000);
      await sleep(800);
      $(".card11 li .right ").eq(0).removeClass("none");
      await sleep(800);
      $(".card11 li .right ").eq(1).removeClass("none");
      numberRoll("num5", 8.99, 2);
      $(".card11 li .img_down ").eq(0).removeClass("none");
      await sleep(800);
      $(".card11 li .right ").eq(2).removeClass("none");
      numberRoll("num6", 2.9, 2);
      $(".card11 li .img_down ").eq(1).removeClass("none");
      await sleep(800);
      $(".card11 li .right ").eq(3).removeClass("none");
      numberRoll("num7", 3.9, 2);
      $(".card11 li .img_down ").eq(2).removeClass("none");
    },
    async playPage12() {
      await sleep(800);
      this.currentIndex = 12;
      this.$refs.audio11.play();
      $(".card12 .title ").removeClass("none");
      $(".card12 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card12 .content_box ").removeClass("none");
      $(".card12 .content_box ").animate({ height: "832px" }, 1000);
    },
    async playPage13() {
      await sleep(800);
      this.currentIndex = 13;
      $(".card13 .title ").removeClass("none");
      $(".card13 .title ").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".card13 .content_box ").removeClass("none");
      $(".card13 .content_box ").animate({ width: "643px" }, 1000);
    },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>